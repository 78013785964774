import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';

const DynamicComponentWithNoSSR = dynamic(
  () => import('components/atomic/molecules/login/UserLogin'),
  {
    ssr: false
  }
);

export default withRouter(DynamicComponentWithNoSSR);
